.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 5vh;
}

.App-body {
  padding: 5vh;
  background-color: gray;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Scanner {
  min-width: 20%;
}

button {
  font-weight: bold;
  padding: 2vh;
  background: white;
  min-height: 5vh;
}
